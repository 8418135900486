<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col class="hidden-sm-and-down">
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">출력갯수</th>
                                            <td class="td">
                                                <select v-model="page.limit">
                                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">검 색 어</th>
                                            <td class="td">
                                                <select v-model="search.searchGubun" class="float-left my-1">
                                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                                    <option value="0">객실명</option>

                                                </select>
                                                <input v-model="search.searchText" placeholder="검색어를 입력해주세요."
                                                       style="width: 55%;"
                                                       v-on:keyup.enter="searchClick">
                                                <v-btn
                                                        color="primary"
                                                        class="mx-3 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="searchClick"
                                                >
                                                    검색
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    객실 목록 ({{gubunDesc}})
                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block float-right"
                                            small
                                            @click="btnClick(3)"
                                    >
                                        객실등록
                                    </v-btn>
                                </v-col>
                                <!--<v-col class="hidden-md-and-down">-->
                                <div class="overflow-x-auto">
                                    <table class="adminListTable tableHover">
                                        <colgroup>
                                            <col style="width:10%"/>
                                            <!--<col style="width:10%"/>-->

                                            <col style="width:7%"/>
                                            <col style="width:7%"/>

                                            <col style="width:9%"/>
                                            <col style="width:9%"/>
                                            <col style="width:9%"/>
                                            <col style="width:9%"/>
                                            <col style="width:9%"/>

                                            <col style="width:9%"/>
                                            <col style="width:7%"/>
                                            <col style="width:2%"/>
                                        </colgroup>
                                        <tr>
                                            <th rowspan="2">객실명</th>
                                            <!--<th rowspan="2">평수</th>-->
                                            <th colspan="2">인원</th>
                                            <th colspan="2">비수기요금</th>
                                            <th colspan="2">준성수기요금</th>
                                            <th colspan="2">성수기요금</th>
                                            <th rowspan="2">진열</th>
                                            <th rowspan="2">수정</th>
                                        </tr>
                                        <tr>
                                            <th>기준</th>
                                            <th>최대</th>
                                            <th>주중</th>
                                            <th>주말</th>
                                            <th>주중</th>
                                            <th>주말</th>
                                            <th>주중</th>
                                            <th>주말</th>
                                        </tr>
                                        <tbody>
                                        <tr
                                                v-for="(item,i) in roomList"
                                                :key="i">
                                            <td>{{item.roomNm}}</td>
                                            <!--<td>{{item.roomArc}}평</td>-->
                                            <td>{{item.stdNop}}명</td>
                                            <td>{{item.maxNop}}명</td>
                                            <td class="text-right">{{item.osrWe | comma}}원</td>
                                            <td class="text-right">{{item.osrWd | comma}}원</td>
                                            <td class="text-right">{{item.spsrWe | comma}}원</td>
                                            <td class="text-right">{{item.spsrWd | comma}}원</td>
                                            <td class="text-right">{{item.psrWe | comma}}원</td>
                                            <td class="text-right">{{item.psrWd | comma}}원</td>
                                            <td>
                                                <template v-if="item.useYn == 'Y'">
                                                    진열
                                                </template>
                                                <template v-else>
                                                    미진열
                                                </template>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="rowClick(item)"
                                                >
                                                    수정
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div class="text-center pt-2">
                                        <v-pagination
                                                v-model="page.page"
                                                :length="page.length"
                                                @input="next"
                                        ></v-pagination>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminRoomList',
        components: {
            AdminRoomMenu
        },
        data: () => ({

            roomList: [],
            roomGubun: 'all',
            gubunDesc: "",
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
            },
            page: {
                limit: 50,  // 한페이지 표시 갯수
                offset: 0,  // %부터 보여주기
                total: '0', // 전체 갯수
                page: 1,    // 현제 페이지
                length: 1   // 페이지 표시 갯수
            },
            search: {
                searchGubun: "0",
                searchText: "",

            },
            isMobile: false,

        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();
            if (this.isMobile) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }

            this.roomGubun = this.$getParameterByName('roomGubun');
            this.gubunDesc = "";
            switch (this.roomGubun) {
                case "R": {
                    this.gubunDesc = "객실";
                    break;
                }
                case "A": {
                    this.gubunDesc = "캠핑장A";
                    break;
                }
                case "B": {
                    this.gubunDesc = "캠핑장B";
                    break;
                }
                case "C": {
                    this.gubunDesc = "캠핑장C";
                    break;
                }
                case "D": {
                    this.gubunDesc = "캠핑장D";
                    break;
                }
                default : {
                    this.gubunDesc = "전체";
                    break;
                }
            }


            this.getList({});

        },
        methods: {
            getList(val) {

                let formdata = {};

                if (val !== undefined) {

                    formdata = val;
                } else {
                    formdata = {}
                }

                switch (this.roomGubun) {
                    case 'R' :
                    case 'A' :
                    case 'B' :
                    case 'C' :
                    case 'D' : {
                        //formdata = {roomGubun: this.roomGubun};
                        formdata['roomGubun'] = this.roomGubun;
                        formdata['delYn'] = "N";
                        break;
                    }
                    case 'X' : {
                        formdata['delYn'] = "Y";
                        break;
                    }
                    default: {
                        formdata['delYn'] = "N";
                        break;
                    }
                }

                formdata['limit'] = this.page.limit;
                formdata['offset'] = this.page.offset;

                this.$store.dispatch("admin/getRoomList", val)
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;
                            this.page.total = resp.total;
                            //this.menuCnt = resp.menuCnt;
                            //this.$router.push({path: "/admin/main"});
                            this.setPage();
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setPage() {

                this.page.length = Math.ceil(this.page.total / this.page.limit);
                if (this.page.length === 0) this.page.length = 1;

            },
            next(page) {
                this.page.page = page;
                this.page.offset = this.page.limit * (this.page.page - 1);
                //this.getList();
                this.searchClick();
            },
            searchClick() {
                let formdata = {};

                switch (this.search.searchGubun) {
                    case "0" : {
                        formdata["roomNmLike"] = this.search.searchText;
                        break;
                    }
                }

                this.getList(formdata);

            },
            rowClick(row) {

                location.href = "/admin/room/info" + '?roomId=' + row.roomId;

            },

            btnClick() {
                location.href = "/admin/room/info";
            },


        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {},
    }
</script>

<style>

</style>

